












import { Component, Vue } from 'vue-property-decorator';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import DayChanger from '@/modules/common/components/day-changer.vue';

@Component({
    components: {
        DayChanger,
        DateDocumentFilter,
    },
})
export default class MarketsDemandMapHeader extends Vue {}
