




import { Component, Vue, Prop } from 'vue-property-decorator';
import MarketsHistoryPopup from '../components/markets-history.popup.vue';

@Component({
    components: { MarketsHistoryPopup },
})
export default class MarketsHistoryModal extends Vue {
    @Prop({ required: false })
    historyDay?: string;
}
