





































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';

@Component
export default class RatesPriceHistoryFilters extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(MarketsServiceS) private marketsService!: MarketsService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    get compSet() {
        if (!this.compsetsService.compsets) {
            return '';
        }

        const currentCompset = this.compsetsService.compsets.find(compset => (
            compset.id === this.documentFiltersService.storeState.settings.compsetId
        ));

        return currentCompset ? `${currentCompset.name} (${currentCompset.type})` : '';
    }

    get source() {
        return this.$route.params.source || this.marketsService.settings.provider;
    }

    get los() {
        return this.documentFiltersService.storeState.settings.los;
    }

    get pos() {
        return this.marketsService.settings.pos;
    }

    getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (!data) {
            return providerName;
        }

        const provider = data.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }
}
